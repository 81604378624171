<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="partner-box-holder">
                    <div class="box">
                        <div class="title">Partner regisztráció</div>
                        <form v-on:submit.prevent="submitForm">
                            <div class="mb-3">
                                <label for="" class="form-label"
                                    >Email cím</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.email"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <!--<div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Jelszó</label>
                                <input type="password" class="form-control" id="exampleInputPassword1">
                            </div>-->
                            <div class="mb-3">
                                <label for="" class="form-label">Cégnév</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.name"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label">Adószám</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.vat_number"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label">Cím</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.address"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label"
                                    >Telefonszám</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.phone"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label"
                                    >Weboldal</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.website"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label"
                                    >Kategória</label
                                >
                                <select
                                    class="form-control"
                                    v-model="form.category_id"
                                    v-if="categories && categories.length > 0"
                                >
                                    <option
                                        v-for="(item, index) in categories"
                                        v-bind:key="'cate-' + index"
                                        :value="item.id"
                                    >
                                        {{ item.name["hu"] }}</option
                                    >
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label"
                                    >Szolgáltatások</label
                                >
                                <textarea
                                    class="form-control"
                                    rows="6"
                                    v-model="form.services"
                                ></textarea>
                                <div id="serviceHelp" class="form-text">
                                    Írd le mivel foglalkozik a céged.
                                </div>
                            </div>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="exampleCheck1"
                                    v-model="form.privacy"
                                />
                                <label
                                    class="form-check-label"
                                    for="exampleCheck1"
                                    >Elfogadom az Adatvédelmi
                                    nyilatkozatot</label
                                >
                            </div>
                            <div class="mb-3 form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="exampleCheck1"
                                    v-model="form.aszf"
                                />
                                <label
                                    class="form-check-label"
                                    for="exampleCheck1"
                                    >Elfogadom az Általános szerződési
                                    feltételeket</label
                                >
                            </div>
                            <button type="submit" class="btn btn-primary">
                                Regisztráció
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "../core/services/api.service";

export default {
    name: "Register",
    data() {
        return {
            form: {
                name: null,
                vat_number: null,
                address: null,
                phone: null,
                email: null,
                website: null,
                category_id: null,
                aszf: null,
                privacy: null,
                services: null
            },
            categories: []
        };
    },
    methods: {
        submitForm() {
            ApiService.post(`/partner/register`, this.form).then(() => {
                this.form = {
                    name: null,
                    vat_number: null,
                    address: null,
                    phone: null,
                    email: null,
                    website: null,
                    category_id: null,
                    aszf: null,
                    privacy: null,
                    services: null
                };

                this.form.category_id = this.categories[0].id;
            });
        }
    },
    beforeMount() {
        ApiService.get(`/category/get-low-level`).then(response => {
            this.categories = response.data;
            this.form.category_id = this.categories[0].id;
        });
    }
};
</script>

<style scoped></style>
